<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
              v-model="formInline.title"
              label="订单标题"
              placeholder="请输入"
              :required="true"
              :rules="[{ required: true, message: '请输入订单标题' }]"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.planTitle"
              label="采购计划"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择采购计划' }]"
              @click="showPickerPlan= true"
          />
          <van-popup v-model="showPickerPlan" position="bottom">
            <van-picker
                show-toolbar
                :columns="planOptions"
                value-key="title"
                @confirm="
                onConfirmPlan($event, 'planId', 'planTitle', 'title', 'showPickerPlan')
              "
                @cancel="showPickerPlan = false"
            />
          </van-popup>

          <van-field
              disabled
              v-model="formInline.typeName"
              label="采购类型"
          />

          <van-field
              disabled
              v-model="formInline.purchaseName"
              label="采购人"
          />

          <van-field
              disabled
              :value="formInline.purchaseDate"
              label="采购日期"
          />

          <van-field
              v-model="formInline.courierNumber"
              label="快递单号"
              placeholder="请输入"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.isQualityStr"
              label="是否质检"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择是否质检' }]"
              @click="showPickerQuality= true"
          />
          <van-popup v-model="showPickerQuality" position="bottom">
            <van-picker
                show-toolbar
                :columns="qualityOptions"
                value-key="dictLabel"
                @confirm="
                onConfirm($event, 'isQuality', 'isQualityStr', 'dictLabel', 'showPickerQuality')
              "
                @cancel="showPickerQuality = false"
            />
          </van-popup>

          <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

          <van-field
              disabled
              :value="formInline.purchasePrice"
              label="物料金额"
          />

          <van-field
              type="number"
              v-model="formInline.discountPrice"
              placeholder="请输入"
              label="优惠金额"
              @input="handleTotalPrice()"
          />

          <van-field
              type="number"
              v-model="formInline.otherPrice"
              placeholder="请输入"
              label="其他费用"
              @input="handleTotalPrice()"
          />

          <van-field
              disabled
              :value="formInline.price"
              label="总金额"
          />

          <div v-for="(item, index) in dataList" :key="index">
            <div class="flex-spa-bet list-tit-wrap">
              <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
            </div>

            <van-field
                v-model="item.materialSpecification"
                label="规格型号"
                disabled
            />

            <van-field
                v-model="item.materialUnit"
                label="单位"
                disabled
            />

            <van-field
                disabled
                v-model="item.supplierName"
                label="供应商"
            />

            <van-field
                v-model="item.supplierScore"
                label="评分"
                type="number"
            />

            <van-field
                v-model="item.price"
                label="单价"
                disabled
            />

            <van-field
                v-model="item.num"
                label="数量"
                disabled
            />

            <van-field
                v-model="item.total"
                label="总金额"
                disabled
            />

            <van-field
                disabled
                v-model="item.remark"
                label="备注"
                placeholder="请输入"
            />

          </div>

          <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

          <div class="line"></div>
          <van-field name="uploader" label="附件" v-if="type != 'detail' && isShowAuth">
            <template #input>
              <van-uploader v-model="uploader" :after-read="afterRead"/>
            </template>
          </van-field>

          <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
          </van-button>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import {addData, selectOrderOne, updateData} from '@/api/purchase/order';
import {selectList, selectPlanOne} from '@/api/purchase/plan';
import AppendixVue from '@/views/components/crm/Appendix.vue';
import {submiteUpload} from "@/api/system/config";

export default {
  components: {AppendixVue},
  data() {
    return {
      formInline: {
        otherPrice: 0,
        discountPrice: 0
      },
      title: '新建订单',
      id: this.$route.query.id,
      type: this.$route.query.type,
      showPickerPlan: false,
      showPickerQuality: false,
      uploader: [],
      uploadParams: {
        businessType: 'pur_order',
        businessId: undefined,
        file: null,
      },
      minDate: new Date(2010, 0, 1),
      //物料数组
      dataList: [],
      //采购计划
      planOptions: [],
      qualityOptions: [
        {'dictLabel': '否', 'dictValue': false},
        {'dictLabel': '是', 'dictValue': true}
      ],
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  mounted() {
    //查询采购计划
    selectList(1).then(response => {
      this.planOptions = response.data
    })
    //回显
    this.findDetail()
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/purchase/purchaseOrder');
      this.$router.go(-1)
    },

    //回显
    async findDetail() {
      if (this.type == 'add') {
        this.formInline = {
          otherPrice: 0,
          discountPrice: 0
        };
        this.title = '新建订单';
      } else {
        let itemDetail = await selectOrderOne(this.id);
        itemDetail = itemDetail.data
        this.formInline = itemDetail;
        this.formInline.isQualityStr = itemDetail.isQuality ? '是' : '否';
        this.dataList = itemDetail.purAnticipatePlanSubDisplays
        if (itemDetail && this.type == 'edit') {
          this.title = '编辑订单';
        } else {
          this.title = '订单详情';
        }
      }
    },

    //确认
    async onConfirm(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['dictValue'];
      this.formInline[keyName] = event[eventKey];
      this[visible] = false;
    },

    //确认
    async onConfirmPlan(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['id'];
      this.formInline[keyName] = event[eventKey];
      this[visible] = false;

      await selectPlanOne(this.formInline.planId).then(
          response => {
            if (!this.formInline.title) {
              this.formInline.title = response.data.title
            }
            this.formInline.type = response.data.type
            this.formInline.typeName = response.data.typeName
            this.formInline.purchaseName = response.data.purchaseName
            this.formInline.purchaseId = response.data.purchaseId
            this.formInline.purchaseDate = response.data.purchaseDate
            this.dataList = response.data.purAnticipatePlanSubDisplays

            this.handleTotalPrice()
            this.$forceUpdate()
          }
      )
    },

    //计算金额
    handleTotalPrice() {
      let purchasePrice = 0
      this.dataList.forEach(item => {
        purchasePrice += Number(item.total)
      })
      this.formInline.purchasePrice = purchasePrice
      this.formInline.price =
          ((Number(this.formInline.purchasePrice) + Number(this.formInline.otherPrice ? this.formInline.otherPrice : 0))
              - Number(this.formInline.discountPrice ? this.formInline.discountPrice : 0)).toFixed(2)
    },

    //备注
    handleContentChange(val) {
      this.formInline.remark = val;
    },

    async onSubmit() {
      this.dataList.forEach(item => {
        delete item.createBy
        delete item.createTime
        delete item.updateBy
        delete item.updateTime
        delete item.companyId
      })

      this.formInline.purAnticipatePlanSubs = this.dataList
      let data = JSON.stringify(this.formInline)

      const result = this.formInline.id ? await updateData(data) : await addData(data);
      if (result.code == 200) {
        this.$toast.success('操作成功');
        if (this.uploadParams.file) {
          this.uploadParams.businessId = result.data
          setTimeout(async () => {
            await submiteUpload(this.uploadParams);
          }, 1000);
        }
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadParams.file = file.file;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-tit {
  padding: 20px 30px;
  font-size: 24px;
  color: #666666;
}

.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

.list-tit-wrap {
  padding: 24px 30px;
  font-size: 26px;
  color: #333333;
}

.add-btn {
  width: 750px;
  height: 110px;
  background: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  line-height: 110px;
  font-size: 30px;
  color: #54afff;
  cursor: pointer;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end
</style>
